import { Vue, Component } from "vue-property-decorator";
import axios, { AxiosInstance } from "axios";
import { paths } from "./apiPaths";
import env from "./env";
import { EventBus } from "./eventBus";

import { translate } from "../plugins/i18n/index";
const apiClient: AxiosInstance = axios.create({
  baseURL: `${env.protocol}${env.serviceDiscovery}`,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": `${env.protocol}${env.serviceDiscovery}`,
    "X-Forwarded-For": `${env.protocol}${env.serviceDiscovery}`,
  },
});

// apiClient.interceptors.request.use(function (config) {
//   const token = localStorage.getItem('token');

//   return config;
// });

export const setTokenHeader = (token: string) => {
  apiClient.defaults.headers.common["x-access-token"] = token;
};

@Component
export default class Request extends Vue {
  $message;
  $loading;
  paths = paths;

  private async baseRequest(
    method: "get" | "post" | "put" | "delete" | "patch",
    url: string,
    entity?: any,
    params?: any
  ): Promise<any> {
    try {
      this.$loading?.startLoading();
      const httpResult = await apiClient({ method, url, data: entity, params });
      this.$loading?.endLoading();

      if (httpResult.status >= 200 && httpResult.status <= 299) {
        return httpResult.data;
      } else {
        this.$message?.errorMessage(httpResult.data);
        throw new Error(httpResult.data);
      }
    } catch (error: any) {
      this.$loading?.endLoading();

      if (error?.response?.data.error === "Unauthorized") {
        this.$message?.errorMessage(`${translate("message.unauthorized")}`);
      } else if (error?.response?.data?.errors) {
        this.$message?.errorMessage(error.response.data.errors);
      } else if (error?.response?.data.error.includes("ENOTFOUND")) {
        EventBus.$emit("getAddInfo", true);
      } else {
        this.$message?.errorMessage(error.response.data.error);
      }
      throw error;
    }
  }

  get(url: string, params?): Promise<any> {
    return this.baseRequest("get", url, null, params);
  }

  async put(url: string, entity): Promise<any> {
    const result = await this.baseRequest("put", url, entity);
    if (entity.isDeleted === true) {
      this.$message?.successMessage(
        `${translate("message.successfullyDeleted")}`
      );
    } else if (result && result.message && result.message != "SUCCESS") {
      
      this.$message?.errorMessage(`${translate(`error.${result.message}`)}`);
    } else {
      this.$message?.successMessage(
        `${translate("message.successfullyChanged")}`
      );
    }
    return result;
  }
  async post(url: string, entity): Promise<any> {
    const result = await this.baseRequest("post", url, entity);
   
    if (
      (result && result.message === "BLOCKED") ||
      result.message === "UNBLOCKED"
    ) {
      this.$message?.successMessage(
        `${translate(`message.${result.message}`)}`
      );
    } else if (
      result &&
      (result.message === "DUPLICATED_CODE" ||
        result.message === "DUPLICATED_CNPJ")
    ) {
      this.$message?.errorMessage(`${translate(`error.${result.message}`)}`);
    } else if (entity.id) {
      this.$message?.successMessage(
        `${translate("message.successfullyChanged")}`
      );
    }
    return result;
  }

  async patch(url: string, entity): Promise<any> {
    const result = await this.baseRequest("patch", url, entity);
    if (result.message === "SUCCESS") {
    this.$message?.successMessage(
      `${translate("message.savedSuccessfully")}`)
    }
    return result;
  }

  delete(url: string, entity): Promise<any> {
    return this.baseRequest("delete", url, entity);
  }

  async remove(url: string, entity): Promise<any> {
    const result = this.baseRequest("delete", url, entity.id);
    this.$message?.successMessage(
      `${translate("message.successfullyDeleted")}`
    );
    return result;
  }
  async save(url: string, entity): Promise<any> {
    if (entity.id) {
      const result = await this.put(url + entity.id, entity);
    
      return result;
    } else {
      
      const result = await this.post(url, entity);
      if (result && result.message) {
        if (result && result.message === "sucess") {
          this.$message?.successMessage(
            `${translate(`message.${result.message}`)}`
          );
        } else if (result && result.message === "SUCESS") {
          this.$message?.successMessage(
            `${translate(`message.${result.message}`)}`
          );
        } else {
          this.$message?.errorMessage(
            `${translate(`error.${result.message}`)}`
          );
        }
      } else {
        this.$message?.successMessage(
          `${translate("message.savedSuccessfully")}`
        );
      }
      return result;
    }
  }
}
