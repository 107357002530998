












































































































































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../services/request";
import { translate } from "../../../plugins/i18n/index";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";
import { HeaderItem } from "../../../types/HeaderItem";
import { TranslateResult } from "vue-i18n";
import { itemsPerPage } from "../../../types/itemsPerPage";
@Component
export default class AllData extends Request {
  @Prop() listSearch: any;
  @Prop() year: any;
  @Prop() month: any;
  @Prop() count: any;
  header: HeaderItem[] = [
    { text: `${translate("code")}`, align: "center", value: "code" },
    { text: `${translate("month")}`, align: "center", value: "month" },
    { text: `${translate("year")}`, align: "center", value: "year" },
    { text: `${translate("marketing")}`, align: "center", value: "marketing" },
    { text: `${translate("service")}`, align: "center", value: "service" },
    { text: `${translate("utility")}`, align: "center", value: "utility" },
    {
      text: `${translate("authentication")}`,
      align: "center",
      value: "authentication",
    },

    { text: `${translate("all")}`, align: "start", value: "all" },
    {
      text: `${translate("menu")}`,
      align: "center",
      value: "menu",
    },
    { text: "", align: "center", value: "data-table-expand" },
  ];
  headerDetails: HeaderItem[] = [
    { text: `${translate("direction")}`, align: "center", value: "direction" },
    { text: `${translate("meta")}`, align: "center", value: "meta" },
    { text: `${translate("chatXIa")}`, align: "center", value: "chatXIa" },
    {
      text: `${translate("comparative")}`,
      align: "center",
      value: "comparative",
    },
  ];
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  search: string = "";
  page: number = 1;
  pageCount: number = 1;
  itemsPerPage: number = 10;
  tableAllId = "allTable";
  expanded = [];
  singleExpand = false;
  list: any = [];
  reportCompare: any = {};
  reportMeta: any = {};
  reportChatBots: any = {};

  directionList = [
    translate("marketing"),
    translate("service"),
    translate("utility"),
    translate("authentication"),
  ];
  loadPagination() {
    this.$emit("upload-pagination", this.page);
  }
  mounted() {
    this.pageCount = Math.ceil(this.count / this.itemsPerPage);
  }
  translateMonth = (month: number): TranslateResult => {
    return this.monthTranslations[month] || "";
  };
  defineColor(value) {
    return value === true ? "#ff0000" : "#32906b";
  }
  async loadDetails({ item }) {
    const currentDate = new Date();
    const month = this.month
      ? this.month
      : (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = this.year ? this.year : currentDate.getFullYear().toString();

    const params = new URLSearchParams();
    params.append("month", month);
    params.append("year", year);

    params.append("take", this.itemsPerPage.toString());
    params.append("code", item?.code);

    const result = await this.get(
      `${this.paths.companyReportDetailsPath}?${params.toString()}`
    );

    this.reportCompare = result.reportCompare;
    this.reportMeta = result.reportMeta;
    this.reportChatBots = result.reportChatBots;
  }
  downloadCsv(list) {
    return list;
  }
  async downloadExcel(listToDownload) {
    let list = await this.downloadCsv(listToDownload);
    if (list.length > 0) {
      let result, keys;

      if (list === null || (!list && !list.length)) {
        return this.$message?.successMessage(translate("downloadReady"));
      }

      const headersToExport = [
        "code",
        "month",
        "year",
        "marketing",
        "service",
        "authentication",
      ];

      keys = Object.keys(list[0]).filter((key) =>
        headersToExport.includes(key)
      );

      result = "";
      result += keys
        .map((key) => {
          return key === "isBlocked" ? translate("status") : key;
        })
        .map((key) => translate(key))
        .join(",");
      result += "\n";

      list.forEach((item) => {
        result += keys
          .map((key) => {
            const cellValue =
              key === "isBlocked"
                ? item[key]
                  ? translate("chip.isBlocked")
                  : translate("active")
                : item[key];

            return typeof cellValue === "string" && cellValue.includes(",")
              ? `"${cellValue}"`
              : cellValue;
          })
          .join(",");
        result += "\n";
      });

      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(result);
      hiddenElement.target = "_blank";

      hiddenElement.download = "relatório.csv";
      hiddenElement.click();
    }
  }
  createPDF() {
    var pdf = new jsPDF("p", "mm", "a4");
    var element = document.getElementById(this.tableAllId);

    html2canvas(element).then((canvas) => {
      var image = canvas.toDataURL("image/png");
      pdf.addImage(
        image,
        "JPEG",
        1,
        1,
        canvas.width * 0.12,
        canvas.height * 0.12,
        "a",
        "FAST"
      );
      pdf.save("dados_exportados" + ".pdf");
    });
  }
  monthTranslations: { [key: number]: TranslateResult } = {
    1: translate("january"),
    2: translate("february"),
    3: translate("march"),
    4: translate("april"),
    5: translate("may"),
    6: translate("june"),
    7: translate("july"),
    8: translate("august"),
    9: translate("september"),
    10: translate("october"),
    11: translate("november"),
    12: translate("december"),
  };
  get validateAllDataList() {
    const dataList = this.listSearch;

    return dataList.map((item) => {
      return {
        ...item,
        marketing: item.marketing ?? "--",
        service: item.service ?? "--",
        utility: item.utility ?? "--",
        code: item.code ?? "--",
        month: item.month ? this.translateMonth(item.month) : "--",
        year: item.year ?? "--",
        authentication: item.authentication ?? "--",
      };
    });
  }
  get disabledDownloads() {
    return this.listSearch.length <= 0;
  }
}
