






























import { Component } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n";
import { paths } from "../../services/apiPaths";
import { ForgotPassword } from "@matrixbrasil/matrix-lib-auth-frontend/src/views";

@Component({
  components: { ForgotPassword },
})
export default class FullForgotPassword extends Request {
  mounted() {
    this.path = paths.forgotPasswordPath;
  }
  buttonText: string = `${translate("button.send")}`;
  emailText: string = `${translate("email")}`;
  phoneText: string = `${translate("phone")}`;
  path: string = "";
  scope: string = "MKP";
  grantType: string = "password";
  logo = require("@/assets/logos-new/LOGO 1.png");
  images = [
    {
      src: require("@/assets/images/IMG-02.jpg"),
    },
  ];
  valid: boolean = true;
  email: string = "";
  emailRules = [
    (v: string) => !!v || `${translate("emailIsRequired")}`,
    (v: string) => /.+@.+\..+/.test(v) || `${translate("validEmail")}`,
  ];
  async forgotPassword(email) {
    return await this.get(this.path + `?email=${email}`);
  }
}
